<template>
  <div :class="{ overflowHidden: token }">
    <div v-if="token" class="web-view-loading">
      <i class="el-icon-loading"></i>
      <div class="loading-text">生成中...</div>
    </div>
    <div v-if="currentResumeInfo" class="box">
      <pefTop @derive="printFn" @printer="printerFn" />
      <div class="preview-Box">
        <div class="preview-left">
          <preview1 v-if="isIndex === 0" ref="preview1" @dialogVisibleFn="dialogVisibleFn" />
          <preview2 v-if="isIndex === 1" ref="preview2" @dialogVisibleFn="dialogVisibleFn" />
          <preview3 v-if="isIndex === 2" ref="preview3" @dialogVisibleFn="dialogVisibleFn" />
          <preview4 v-if="isIndex === 3" ref="preview4" @dialogVisibleFn="dialogVisibleFn" />
          <preview5 v-if="isIndex === 4" ref="preview5" @dialogVisibleFn="dialogVisibleFn" />
        </div>
        <div class="preview-rigth">
          <div class="title">在线简历模板</div>
          <div class="preview-list" v-if="onlineResumeList.length">
            <div
              class="list-box"
              v-for="(item, index) in onlineResumeList"
              :key="index"
              @click="selectFn(item)"
              :class="{ actvie: index === isIndex }"
            >
              <div class="box-baseimg" v-if="index === isIndex">
                <img src="@/assets/image/resumeLogin/勾选.png" alt="" />
              </div>
              <img :src="item.img" alt="" class="pdf-img" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="简历发送至邮箱" :visible.sync="dialogVisible" width="30%" @close="resetForm">
      <el-form ref="form" :model="form" label-width="80px" :rules="rules">
        <el-row>
          <!-- <el-col :span="18">
            <el-form-item label="手机号" prop="phone">
              <el-input v-model.trim="form.phone"></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="18">
            <el-form-item label="邮箱" prop="email">
              <el-input v-model.trim="form.email" @keyup.enter.native="submitForm" />
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item>
              <el-button type="primary" @click="submitForm" class="suint" v-loading="loadingSubmit">
                确定
              </el-button>
              <el-button @click="resetForm">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { upyunUrl } from '@/api/config'
import pefTop from '@/components/pefTop'
import preview1 from '@/views/resume/pdfExport/preview1'
import preview2 from '@/views/resume/pdfExport/preview2'
import preview3 from '@/views/resume/pdfExport/preview3'
import preview4 from '@/views/resume/pdfExport/preview4'
import preview5 from '@/views/resume/pdfExport/preview5'

export default {
  components: {
    pefTop,
    preview1,
    preview2,
    preview3,
    preview4,
    preview5
  },
  data() {
    // const validatePhone = (rule, value, callback) => {
    //   if (
    //     /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/.test(
    //       value
    //     )
    //   ) {
    //     callback()
    //   } else {
    //     return callback(new Error('请输入正确的手机号'))
    //   }
    // }
    const validateEmail = (rule, value, callback) => {
      if (/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)) {
        callback()
      } else {
        return callback(new Error('请输入正确的邮箱'))
      }
    }
    return {
      toGetPdfState: 2, // 默认为2  2就是为pc的发送邮箱不需要下载导出pdf 1就是小程序端跳转
      loadingPdf: null, //  点击导出的加载状态
      loadingSubmit: false, // 发送邮箱的确定按钮状态
      // 用户当前选择的模板下标
      isIndex: 0,
      dialogVisible: false, // 弹窗状态
      onlineResumeList: [], //在线简历数据
      resumeId: 0, // 当前简历id
      token: null, // 用户token
      type: null, // 小程序进来的类型 diagnose = 简历诊断、img = 简历预览图片、file = 下载简历
      resumeName: null, //简历名称
      upyunData: { policy: '', signature: '' },
      form: {
        // phone: '', // 手机号
        email: '', // 邮箱
        resume_url: '' // pdf在线预览下载链接
      },
      rules: {
        // phone: [
        //   { required: true, message: '请输入手机号', trigger: 'change' },
        //   { validator: validatePhone, trigger: 'change' }
        // ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'change' },
          { validator: validateEmail, trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    ...mapState(['currentResumeInfo'])
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  created() {
    this.getOnlineResumeLisFn()
    if (this.$route.query.id) this.resumeId = this.$route.query.id
    if (this.$route.query.token) {
      this.token = this.$route.query.token
      sessionStorage.setItem('token', this.token)
      this.setToken(this.token)
    }
    if (this.$route.query.type) this.type = this.$route.query.type
    if (this.$route.query.isIndex) this.isIndex = parseInt(this.$route.query.isIndex)
    this.getResumeGenerateContentFn()
    this.getUpYunSignFn()
  },
  methods: {
    ...mapMutations({
      setToken: 'SET_TOKEN',
      setCurrentResumeInfo: 'SET_CURRENT_RESUME_INFO',
      setCurrentResumeEditId: 'SET_CURRENT_RESUME_EDIT_ID'
    }),
    ...mapActions([
      'getResumeGenerateContent',
      'resumeGenerateSave',
      'resumeFileUpyun',
      'getUpYunSign',
      'getOnlineResumeList',
      'getResumeAnalysisEmail'
    ]),
    // 获取在线模板简历列表
    async getOnlineResumeLisFn() {
      this.onlineResumeList = await this.getOnlineResumeList()
    },
    // 用户选择模板
    selectFn(item) {
      this.isIndex = item.isIndex
    },
    async getResumeGenerateContentFn() {
      await this.getResumeGenerateContent({ id: this.resumeId || 0 })
      if (this.token) {
        if (this.type === 'diagnose' || this.type === 'file') {
          this.toGetPdfState = 1
          this.printFn()
          // this.toGetPdf() // 生成小程序简历诊断文件流转网络路径 || 简历下载
        } else if (this.type === 'img') {
          this.scImg() // 生成小程序简历诊断预览图片
        }
      }
    },
    // 获取简历名字
    getName() {
      this.resumeName = this.currentResumeInfo.resume_name
      if (this.resumeName === null) {
        // 拼接导出文件名字
        this.resumeName = `${this.currentResumeInfo.name || 'xxx'}-${
          this.currentResumeInfo.intention.length
            ? this.currentResumeInfo.intention[0].intentionName
            : '岗位'
        }-${this.currentResumeInfo.num_work_experience || ''}年/${
          this.currentResumeInfo.education_experience.length
            ? this.currentResumeInfo.education_experience[0].end_time_year
            : ''
        }届毕业`
      }
    },
    // 获取文件流方法
    // async toGetPdf(tile = '', download = true) {
    //   this.getName()
    //   // download：false为不下载
    //   this.getPdf(tile, download).then(res => {
    //     this.UploadPdf(res)
    //   })
    // },
    // // 上传pdf接口
    // async UploadPdf(res) {
    //   // res拿到base64的pdf
    //   let pdfBase64Str = res
    //   let title = this.resumeName
    //   // 把简历名称存起来方便resumeFileUpyunFn接口取值
    //   sessionStorage.setItem('resume_title', title)
    //   let file = await this.dataURLtoFile(pdfBase64Str, title + '.pdf') // 调用一下下面的转文件流函数
    //   await this.resumeFileUpyunFn(file)
    // },
    // 将base64转换为文件,接收2个参数，第一是base64，第二个是文件名字最后返回文件对象
    // dataURLtoFile(dataurl, filename) {
    //   var arr = dataurl.split(','),
    //     mime = arr[0].match(/:(.*?);/)[1],
    //     bstr = atob(arr[1]),
    //     n = bstr.length,
    //     u8arr = new Uint8Array(n)
    //   while (n--) {
    //     u8arr[n] = bstr.charCodeAt(n)
    //   }
    //   return new File([u8arr], filename, { type: mime })
    // },
    // 上传简历
    // async resumeFileUpyunFn(file) {
    //   console.log(file)
    //   const data = new FormData()
    //   data.append('file', file)
    //   data.append('policy', this.upyunData.policy)
    //   data.append('signature', this.upyunData.signature)
    //   const obj = await this.resumeFileUpyun(data)
    //   const oJson = {
    //     id: this.currentResumeInfo.id,
    //     resume_url: upyunUrl + obj.url,
    //     resume_name: this.resumeName,
    //     file_size: obj.file_size,
    //     all_result_n: null
    //   }
    //   if (this.toGetPdfState === 1) {
    //     // 小程序跳转过来执行
    //     this.resumeGenerateSaveFn(oJson)
    //   } else {
    //     // 点击页面一键导出的方法
    //     this.form.resume_url = oJson.resume_url // 拿到pdf的在线链接 用于发送到用户的邮箱
    //     this.loadingPdf.close() // 关闭loadingPdf状态
    //     this.dialogVisible = true // 打开弹窗
    //   }
    // },
    // 获取又拍云签名
    async getUpYunSignFn() {
      this.upyunData = await this.getUpYunSign()
    },
    // 把简历模板一转换成图片
    async scImg() {
      const obj = await this.$refs[`preview${this.isIndex + 1}`].scImg()
      this.resumeGenerateSave({
        id: this.currentResumeInfo.id,
        resume_cover: obj.url,
        all_result_n: null
      }).then(() => {
        wx.miniProgram.redirectTo({
          url: `/pages/resumeSystem/previewResume/index?id=${this.currentResumeInfo.id}&isIndex=${this.isIndex}`
        })
      })
    },
    // 当用户选择导出
    printFn() {
      this.$refs[`preview${this.isIndex + 1}`].printFn()
    },
    // 当用户选择打印
    printerFn() {
      this.$refs[`preview${this.isIndex + 1}`].handlePrint()
    },
    // 子组件触发的方法 打开发送邮箱的弹窗 或者触发小程序的方法
    dialogVisibleFn(oJson) {
      if (this.toGetPdfState === 1) {
        // 小程序跳转过来执行
        this.resumeGenerateSaveFn(oJson)
      } else {
        // pc端执行
        this.form.resume_url = oJson.resume_url // 拿到pdf的在线链接 用于发送到用户的邮箱
        this.dialogVisible = true // 打开弹窗
      }
    },
    // 保存简历内容
    async resumeGenerateSaveFn(oJson) {
      await this.resumeGenerateSave(oJson)
      if (this.type === 'diagnose') {
        wx.miniProgram.redirectTo({
          url: `/pages/resumeSystem/diagnosis/uploadResume/index?id=${oJson.id}`
        })
      } else if (this.type === 'file') {
        wx.miniProgram.navigateBack()
      }
    },
    // 发送简历至用户邮箱的方法
    async getResumeAnalysisEmailFn() {
      this.loadingSubmit = true
      const res = await this.getResumeAnalysisEmail(this.form)
      if (res && res.code === 200) {
        this.$message({
          message: '发送成功,请注意查收邮箱',
          type: 'success'
        })
      } else {
        this.$message.error('服务器繁忙,请稍后重试')
      }
      this.loadingSubmit = false
      this.resetForm()
    },
    // 当用户弹窗点击了确定
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.getResumeAnalysisEmailFn()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 用户弹窗点击了取消
    resetForm() {
      this.dialogVisible = false
      this.$refs.form.resetFields()
    },
    // 离开页面触发的方法
    async leaveFn() {
      // 在每次离开页面的时候清空vuex的数据 确保回来的时候进行接口的请求获取最新数据
      await this.setCurrentResumeInfo(null)
      // 在每次离开前把id默认为0
      await this.setCurrentResumeEditId(0)
    }
  },
  // 组件内的路由前置守卫
  async beforeRouteLeave(to, from, next) {
    await this.leaveFn()
    next()
  }
}
</script>

<style scoped lang="stylus">
.overflowHidden
  overflow hidden
.box
  background #f0f2f5
  min-height 100vh
  height auto
  .preview-Box
    display flex
    justify-content center
    margin-top 20px
    .preview-left
      width 828px
      min-height height auto
      background #FFFFFF
      box-shadow 0 0 20px 0 rgba(221, 221, 221, 0.4)
      border-radius 10px
      margin-right 20px
    .preview-rigth
      overflow hidden
      overflow-y scroll
      width 350px
      height 1000px
      background #FFFFFF
      box-shadow 0 0 20px 0 rgba(221, 221, 221, 0.4)
      border-radius 10px
      padding 30px 48px 30px 52px
      box-sizing border-box
      .title
        text-align center
        font-size 20px
        font-family Source Han Sans CN
        font-weight 500
        color #333333
        margin-bottom 24px
      .preview-list
        .list-box
          width 251px
          height 355px
          background #FFFFFF
          box-shadow 0px 0px 20px 0px rgba(221, 221, 221, 0.86)
          border-radius 6px
          position relative
          margin-bottom 20px
          cursor pointer
          .box-baseimg
            position absolute
            bottom -2px
            right -1px
          img
            border-radius 6px
            width 100%
            height 100%
        .list-box:hover
          border 1px solid #003DBB
      .actvie
        border 1px solid #003DBB
    /* 滚动条样式 */
    .preview-rigth::-webkit-scrollbar /* 滚动条整体样式 */
      width 4px /* 高宽分别对应横竖滚动条的尺寸 */
      height 4px
    .preview-rigth::-webkit-scrollbar-thumb /* 滚动条里面小方块 */
      border-radius 10px
      -webkit-box-shadow inset 0 0 5px rgba(0, 0, 0, 0.3)
      background rgba(0, 0, 0, 0.2)
    .preview-rigth::-webkit-scrollbar-track /* 滚动条里面轨道 */
      -webkit-box-shadow inset 0 0 5px rgba(0, 0, 0, 0.2)
      border-radius 0
      background rgba(0, 0, 0, 0.1)
.web-view-loading
  position fixed
  left 0
  right 0
  top 0
  bottom 0
  width 100%
  height 100%
  z-index 999
  display flex
  justify-content center
  align-items center
  flex-direction column
  background-color #fff
  .el-icon-loading
    font-size 30px
    color #409eff
  .loading-text
    padding-top 10px
    font-size 14px
    color #409eff
/deep/ .el-dialog
  border-radius 10px
/deep/ .el-input__inner:focus
        border-color #003dbb
</style>
